import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigation } from './navigation';
import { Kreislauf } from './kreislauf';
import { Settings } from './settings';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KhtStoreService {

  kreislaeufe: Kreislauf[];
  startnavigation: Navigation[];
  allTurns;
  // baseURL = 'https://www.techtool.mahle.com/';
  // baseURL = 'https://techtool.clients.motor4.com/';
  baseURL;

  constructor(private http: HttpClient) {
    const domain = window.location.hostname;
    if(domain === 'www.techtool.clients.motor4.com' || domain === 'techtool.clients.motor4.com') {
      this.baseURL = 'https://techtool.clients.motor4.com/';
    } else {
      this.baseURL = 'https://www.techtool.mahle.com/';
    }
  }

  getSettings(pLanguage): Observable<Settings> {
    pLanguage = this.getAllowedLanguages(pLanguage);
    return this.http.get<Settings>(`assets/data/${pLanguage}/setting.json`);
  }

  getAll(pLanguage, pVehicle) {

    pLanguage = this.getAllowedLanguages(pLanguage);
    if (pVehicle === 'pkw' || pVehicle === 'nkw') {
      return this.http.get<Navigation[]>(`assets/data/${pLanguage}/navigation_${pVehicle}.json`);
    }
    return this.http.get<Navigation[]>(`assets/data/en/navigation_pkw.json`);

  }

  getKreislaufData(pLanguage, pKreislauf) {

    const isMesse = document.body.classList.contains('messe');
    pLanguage = this.getAllowedLanguages(pLanguage);
    if(!isMesse) {
      return this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=${pKreislauf}`);
    } else {
      return this.http.get<any>(`assets/data/${pLanguage}/${pKreislauf}.json`);
    }
  }

  getAllKreislaufData( pLanguage, pVehicle ) {

    pLanguage = this.getAllowedLanguages(pLanguage);
    const isMesse = document.body.classList.contains('messe');

    if (pVehicle === 'pkw') {
      if(!isMesse) {
        const EC = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=ec`);
        const AC1 = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=ac1`);
        const AC2 = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=ac2`);
        const AC3 = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=ac3`);
        const AC4 = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=ac4`);
        const AC2V = this.http.get<any>(`${this.baseURL}bauteile.php?lan=${pLanguage}&turn=ac2v`);
        const LLK = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=llk`);
        const ILLK = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=illk`);
        const ILLK2 = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=illk2`);
        const HV = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=hv`);
        const HV2 = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=hv2`);

        return forkJoin([EC, AC1, AC2, AC2V, AC3, AC4, LLK, ILLK, ILLK2, HV, HV2]);

      } else {
        const EC = this.http.get<any>(`assets/data/${pLanguage}/ec.json`);
        const AC1 = this.http.get<any>(`assets/data/${pLanguage}/ac1.json`);
        const AC2 = this.http.get<any>(`assets/data/${pLanguage}/ac2.json`);
        const AC3 = this.http.get<any>(`assets/data/${pLanguage}/ac3.json`);
        const AC4 = this.http.get<any>(`assets/data/${pLanguage}/ac4.json`);
        const AC2V = this.http.get<any>(`assets/data/${pLanguage}/ac2v.json`);
        const LLK = this.http.get<any>(`assets/data/${pLanguage}/llk.json`);
        const ILLK = this.http.get<any>(`assets/data/${pLanguage}/illk.json`);
        const ILLK2 = this.http.get<any>(`assets/data/${pLanguage}/illk2.json`);
        const HV = this.http.get<any>(`assets/data/${pLanguage}/hv.json`);
        const HV2 = this.http.get<any>(`assets/data/${pLanguage}/hv2.json`);

        return forkJoin([EC, AC1, AC2, AC2V, AC3, AC4, LLK, ILLK, ILLK2, HV, HV2]);

      }

    } else if (pVehicle === 'nkw') {

      if(!isMesse) {

        const EC_LKW = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=ec_lkw`);
        const AC_LKW = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=ac_lkw`);
        const LLK_LKW = this.http.get<any>(`${this.baseURL}bauteile.php?lang=${pLanguage}&turn=llk_lkw`);

        return forkJoin([EC_LKW, AC_LKW, LLK_LKW]);

      } else {

        const EC_LKW = this.http.get<any>(`assets/data/${pLanguage}/ec_lkw.json`);
        const AC_LKW = this.http.get<any>(`assets/data/${pLanguage}/ac_lkw.json`);
        const LLK_LKW = this.http.get<any>(`assets/data/${pLanguage}/llk_lkw.json`);

        return forkJoin([EC_LKW, AC_LKW, LLK_LKW]);

      }

    }

  }

  getKreislauf(pKreislauf) {
    for (const kreislauf of this.kreislaeufe) {
      if ( kreislauf.ident === pKreislauf ) {
        return kreislauf;
      }
    }
  }

  getLegalNoticeData(pLanguage) {

    pLanguage = this.getAllowedLanguages(pLanguage);
    return this.http.get<any>(`assets/data/${pLanguage}/datenschutz.json`);

  }

  getImprintData(pLanguage) {
    pLanguage = this.getAllowedLanguages(pLanguage);
    return this.http.get<any>(`assets/data/${pLanguage}/imprint.json`);
  }

  getSearchResults(pLanguage, pSearch) {

    pLanguage = this.getAllowedLanguages(pLanguage);
    return this.http.get<any>(`${this.baseURL}suche.php?lang=${pLanguage}&searchString=${pSearch}`);

  }

  getAllowedLanguages(pLanguage) {

    const languages = ['cn', 'de', 'en', 'es', 'fr', 'gr', 'hu', 'it', 'nl', 'no', 'mx', 'pl', 'pt', 'tr', 'us'];

    if (languages.includes(pLanguage)) {
      return pLanguage;
    } else {
      return 'en';
    }

  }

}
